import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from '../utils/popUpCookieUtils';

const modalData = [
  {
    id: 1,
    imgSrc: '/img/pop/pop01.png',
    alt: '광고 이미지 1',
    cookieKey: 'hideAdModal1',
    linkUrl: '/cost',
    buttonStyles: {
      top: '70%',
      left: '25%',
      width: '60%',
      height: '15%',
    },
  },
  {
    id: 2,
    imgSrc: '/img/pop/pop02.png',
    alt: '광고 이미지 2',
    cookieKey: 'hideAdModal2',
    linkUrl: '/cost',
    buttonStyles: {
      display: 'none',
    },
  },
  {
    id: 3,
    imgSrc: '/img/pop/pop03.png',
    alt: '광고 이미지 3',
    cookieKey: 'hideAdModal3',
    linkUrl: '/cost',
    buttonStyles: {
      top: '70%',
      left: '25%',
      width: '60%',
      height: '15%',
    },
  },
];

const AdContent = ({ modal, isVisible, onHideTodayChange, onClose }) => {
  const navigate = useNavigate();

  if (!isVisible) return null;

  return (
    <div className="content" style={{ position: 'relative' }}>
      <div className="img-wrap">
        <img src={modal.imgSrc} alt={modal.alt} style={{ width: '100%' }} />
        <div
          onClick={() => navigate(modal.linkUrl)}
          style={{
            position: 'absolute',
            top: modal.buttonStyles.top,
            left: modal.buttonStyles.left,
            width: modal.buttonStyles.width,
            height: modal.buttonStyles.height,
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
        ></div>
      </div>
      <div className="btn-wrap">
        <div className="chk-wrap">
          <input
            type="checkbox"
            onChange={(e) =>
              onHideTodayChange(modal.id, modal.cookieKey, e.target.checked)
            }
          />
          <span>오늘 그만보기</span>
        </div>
        <button onClick={() => onClose(modal.id)}>닫기</button>
      </div>
    </div>
  );
};

export const AdModal = () => {
  const [visibleModals, setVisibleModals] = useState({});

  useEffect(() => {
    const initialVisibility = modalData.reduce((acc, modal) => {
      const isHidden = getCookie(modal.cookieKey);
      acc[modal.id] = !isHidden; // 쿠키가 없으면 true (보여줌)
      return acc;
    }, {});
    setVisibleModals(initialVisibility);
  }, []);

  const handleHideTodayChange = (id, cookieKey, isChecked) => {
    if (isChecked) {
      setCookie(cookieKey, 'true', 1); // 쿠키 설정
      setVisibleModals((prev) => ({ ...prev, [id]: false })); // 해당 모달 즉시 닫기
    }
  };

  const handleCloseModal = (id) => {
    setVisibleModals((prev) => ({ ...prev, [id]: false }));
  };

  return (
    <div className="ad-modal">
      <div className="content-wrap">
        {modalData.map((modal) => (
          <AdContent
            key={modal.id}
            modal={modal}
            isVisible={visibleModals[modal.id]}
            onHideTodayChange={handleHideTodayChange}
            onClose={handleCloseModal}
          />
        ))}
      </div>
    </div>
  );
};
