import { Map, MapMarker } from 'react-kakao-maps-sdk';

export default function KakaoMap() {
  return (
    <div style={{ width: '100%', height: '400px' }}>
      {' '}
      {/* 부모 요소에 반응형 크기 적용 */}
      <Map
        center={{ lat: 37.60087, lng: 126.89017 }} // 지도의 중심 좌표
        style={{ width: '100%', height: '100%' }} // 지도 크기를 부모 요소에 맞게 설정
        level={5} // 지도 확대 레벨
      >
        <MapMarker position={{ lat: 37.60087, lng: 126.89017 }}></MapMarker>
      </Map>
    </div>
  );
}
